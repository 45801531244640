import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide16/image/imgPortadaGuia16.png"
import img1 from "@components/pageGuide/guides/guide16/image/img1.png"
import slider1 from "@components/pageGuide/guides/guide16/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide16/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide16/image/silder3.png"

const title = (
  <> 
    <h1 className="titles-underlined-ebook ebook-ventas">
      12 KPIs para tus campañas de telemarketing
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
      Aprende cómo medir la efectividad, el retorno de inversión y el impacto de
      tus acciones de telemarketing, y obtén consejos prácticos para optimizar
      tus estrategias y maximizar tus resultados.
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
    {" "}
    En esta guía conocerás los KPIs más relevantes para evaluar el desempeño y
    el impacto de tus campañas de telemarketing. Además, descubrirás a cómo
    medir y analizar cada métrica clave para tomar decisiones informadas y
    mejorar tus estrategias de telemarketing.
    <br /> <br />
    Conocer estos KPIs te ayudarán a identificar oportunidades de mejora,
    ajustar el enfoque y optimizar los recursos para lograr resultados
    sobresalientes en tus campañas. De esta forma, podrás alcanzar el éxito en
    tu gestión de telemarketing.
  </p>
)

const data = {
  start: {
    support: "Guía | Telemarketing",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía-para-una-buena-experiencia-de-compra",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Medición de la productividad.",
      },
      {
        key: 2,
        text: "Tasa de abandono y resolución de llamadas.",
      },
      {
        key: 3,
        text: "Ingresos por llamada exitosa.",
      },
      {
        key: 4,
        text: "Costos por contacto y por llamada.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
